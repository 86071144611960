import React, { useState } from "react"
import prettyBytes from 'pretty-bytes'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export default function DocumentCard(props) {
    const [active, setActive] = useState(false);
    function toggleActive() {
        setActive(!active)
    }

    function Attachments() {
        if(props.attachments.length > 0){
            if(active){
                return(
                    <>{props.attachments.map(attachment => (
                    <div key={attachment.id} className="card-footer">
                        <a href={attachment.document.url} target="_blank" className="card-footer-item">{attachment.title}</a>
                        <div className="card-footer-item">{prettyBytes(attachment.document.size*1000)}</div>
                    </div>
                    ))}
                    <div className="card-footer">
                        <button onClick={toggleActive} className="button is-text card-footer-item">Hide Attachments</button>
                    </div>
                    </>
                )
            } else {
                return(
                    <div className="card-footer">
                        <button onClick={toggleActive} className="button is-text card-footer-item">Show Attachments</button>
                    </div>
                )
            }
        } else {
            return (<></>)
        }
    }
    return (
          <div className="document-grid-item">
            <div className="card" style={{marginBottom: '1rem'}}>
              <div className="card-content">
                <p className="is-size-6">{props.title}<br />
                <small className="has-text-grey">{props.subtitle}</small>
                </p>
              </div>
              <div className="card-footer">
                <a href={props.rootLink} target="_blank" className="card-footer-item">{props.rootLinkName}</a>
                <div className="card-footer-item">{prettyBytes(props.rootLinkSize*1000)}</div>
              </div>
              <Attachments />
            </div>
          </div>
    )
}